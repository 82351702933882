import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { v4 } from 'uuid'

const NavItem = styled(Link)`
  text-decoration: none !important;
  color: #111;
  display: inline-block;
  white-space: nowrap;
  margin: 0 1vw;
  transition: all 200ms ease-in;
  position: relative;

  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: ".";
    color: transparent;
    background: #c80000;
    height: 1px;
    transition: all 0.2s ease-in;
  }

  :hover {
    color: '#c80000';
    ::after {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    padding: 20px 0;
    z-index: 6;
  }
`
//export const BlocPageQuery = graphql`
const NavSubmenu = (sublinks) => {

  const setClassActive = (sublink) => {
    const url = sublinks.pageContext?.url;
    return url && url.includes(sublink.url.split('/').pop()) ? 'subnav-item active' : 'subnav-item'
  }
    

    return (
        <>
              {sublinks.nestedPages && sublinks.nestedPages.map(sublink => (
                <NavItem key={v4()} 
                className={setClassActive(sublink)} 
                to={"/" + sublink.url}>
                {sublink.url_title}
                </NavItem>
            ))}
        </>
    )
}

export default NavSubmenu