import React, { Fragment } from "react"
import styled from "styled-components"
import { Link, useStaticQuery, graphql } from "gatsby"

const LogoWrap = styled.div`
  margin: auto 0;
  min-height: 50px;

  
`
const Logo = () => {
  const data = useStaticQuery(graphql`
    query {
       file: markdownRemark(frontmatter: {collectionKey: {eq: "settings"}}) {
            siteMetadata: frontmatter {
              site_description
              site_title
              logo_header
              logo_footer          
            }
          } 
    }
  `)

  return (
      <Fragment>
    <LogoWrap as={Link} to="/" className="d-none d-md-block">
    <img src={data.file.siteMetadata.logo_header} 
    style={{width: '120px', height: '50px'}} alt={data.file.siteMetadata.site_title}></img>   
    </LogoWrap>
      <LogoWrap as={Link} to="/" className="d-xs-block d-md-none pt-1 pl-4">
      <img src={data.file.siteMetadata.logo_header} 
      style={{width: '83px', height: '35px'}} alt={data.file.siteMetadata.site_title}></img>  
    </LogoWrap>
    </Fragment>
  )
}

export default Logo
