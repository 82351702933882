import React, { useState, Fragment } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import NavbarLinks from "./NavbarLinks"
import NavMobileLinks from "./NavMobileLinks"
import Logo from "./Logo"
import NavSubmenu from "./NavSubmenu"


const Toggle = styled.div`
  display: none;
  height: 100%;
  cursor: pointer;
  padding: 0 10vw;

  @media (max-width: 768px) {
    display: flex;
  }
`
const NavboxMobile = styled.div`
  @media (max-width: 768px) {
    left: ${props => (props.open ? "-100%" : "0")};
  }
`

const Hamburger = styled.div`
  background-color: #111;
  width: 30px;
  height: 3px;
  transition: all 0.3s linear;
  align-self: center;
  position: relative;
  transform: ${props => (props.open ? "rotate(-45deg)" : "inherit")};

  ::before,
  ::after {
    width: 30px;
    height: 3px;
    background-color: #111;
    content: "";
    position: absolute;
    transition: all 0.3s linear;
  }

  ::before {
    transform: ${props =>
    props.open ? "rotate(-90deg) translate(-10px, 0px)" : "rotate(0deg)"};
    top: -10px;
  }

  ::after {
    opacity: ${props => (props.open ? "0" : "1")};
    transform: ${props => (props.open ? "rotate(90deg) " : "rotate(0deg)")};
    top: 10px;
  }
`
const Navbar = (layoutContext) => {
  const [navbarOpen, setNavbarOpen] = useState(false)

  const data = useStaticQuery(graphql`
  query {
     links: allMarkdownRemark(filter: {frontmatter: {collectionKey: {eq: "navigation"}, visible: { eq: true}}}, sort: {fields: [frontmatter___position], order: ASC}) {
        edges {
          node {
            id
            frontmatter {
              url
              url_title
              seo_title
              seo_description
              page        
              nested_pages {
                url
                url_title
                seo_title
                seo_description
              }
            }
          }
        }
  }
}
`)

  const links = data.links.edges;
  
  const nestedPages = layoutContext.pageContext?.nestedPages?.sort((a, b) => a.position - b.position);

  return (
    <Fragment>
      <nav className="navbar1">
        <Logo />
        <Toggle
          navbarOpen={navbarOpen}
          onClick={() => setNavbarOpen(!navbarOpen)}
        >
          {navbarOpen ? <Hamburger open /> : <Hamburger />}
        </Toggle>
        {navbarOpen ? (
          <Fragment >
            <div className="navbar1-links d-none d-md-block">
              <NavbarLinks links={links} pageContext={layoutContext.pageContext} />
            </div>
            <NavboxMobile className="nav-mobile d-sm-block d-md-none pl-4">
              <NavMobileLinks links={links} pageContext={layoutContext.pageContext}/>
            </NavboxMobile>
          </Fragment>
        ) : (
          <div className="navbar1-links d-none d-md-block" open>
              <NavbarLinks links={links} pageContext={layoutContext.pageContext} />
              </div>
          )}
      </nav>
      {nestedPages &&
        <nav className="navbar2">
          <div className="navbar2-links">
            <NavSubmenu nestedPages={nestedPages} pageContext={layoutContext.pageContext} />
          </div>
        </nav>}
    </Fragment>
  )
}

export default Navbar
