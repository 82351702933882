import React from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import '../stylesheets/main.scss'
import useSiteMetadata from './SiteMetadata'

const TemplateWrapper = ({ children, pageContext }) => {
  const { site_title, site_description, stylesheet } = useSiteMetadata();
  const cloudinaryCloud = 'groupea';
  const currentCSS = process.env.STYLESHEET_DEV === '1' ? '/css/styles.css' : stylesheet;

  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{site_title}</title>
        <meta name="description" content={site_description} />
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous"></link>
        <link rel="stylesheet" type="text/css" href={currentCSS} />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`https://res.cloudinary.com/${cloudinaryCloud}/image/upload/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`https://res.cloudinary.com/${cloudinaryCloud}/image/upload/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`https://res.cloudinary.com/${cloudinaryCloud}/image/upload/favicon-16x16.png`}
          sizes="16x16"
        />

        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={site_title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`https://res.cloudinary.com/${cloudinaryCloud}/image/upload/logo_au_a.png`}
        />
      </Helmet>
      <Navbar pageContext={pageContext}/>
      
      <div>{children}</div>
      <Footer pageContext={pageContext} />
    </div>
  )
}

export default TemplateWrapper
