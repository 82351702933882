import { graphql, useStaticQuery } from 'gatsby'

const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query SITE_METADATA_QUERY {
        site: markdownRemark(frontmatter: {collectionKey: {eq: "settings"}}) {
          id
          html
          siteMetadata: frontmatter {
            site_title
            site_description      
            stylesheet     
          }
        }
      }
    `
  )
  return site.siteMetadata
}

export default useSiteMetadata
