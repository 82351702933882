import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const NavItem = styled(Link)`
  text-decoration: none !important;
  color: #111;
  display: inline-block;
  white-space: nowrap;
  margin: 0 1vw;
  transition: all 200ms ease-in;
  position: relative;

  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: ".";
    color: transparent;
    background: #c80000;
    height: 1px;
    transition: all 0.2s ease-in;
  }

  :hover {
    color: '#c80000';
    ::after {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    padding: 20px 0;
    z-index: 6;
  }
`

const NavbarLinks = (data) => {
  const links = data.links;

  const setClassActive = (link) => {
    return link.frontmatter.url === data.pageContext?.parentUrl ? 'navbar-item active' : 'navbar-item'
  }

  return (
    <>
     {links &&
                links.map(({ node: link }) => (                  
                  <NavItem key={link.id} className={setClassActive(link)} to={"/" + link.frontmatter.url}>
                   {link.frontmatter.url_title}
                  </NavItem>
                ))
              }

    </>
  )
}

export default NavbarLinks