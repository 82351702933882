import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { v4 } from 'uuid'

const NavItem = styled(Link)`
  text-decoration: none !important;
  display: block;
`

const NavMobileSubmenu = (data) => {

    const setClassActive = (link) => {
        return link.url === data.pageContext?.parentUrl ? 'active' : null
      }

    return (
        <>
            {data.sublinks.map(sublink => (
               <li key={v4()}> <NavItem  activeClassName="mobile-active" className={setClassActive(sublink)} to={"/" + sublink.url}>
                    {sublink.url_title}
                </NavItem>
                </li>
            ))}
        </>
    )
}

export default NavMobileSubmenu