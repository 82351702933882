import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import NavMobileSubmenu from "./NavMobileSubmenu"

const NavItem = styled(Link)`
  text-decoration: none !important;
  display: block;
  color: #000000 !important;
  margin: 0 1vw;
`

const NavMobileLinks = (data) => {
  const links = data.links;
  
  const setClassActive = (link) => {
    return link.frontmatter.url === data.pageContext?.parentUrl ? 'active' : null
  }
  
  return (
    <>
     {links && links.map(({ node: link }) => (  
                 
                  <NavItem  key={link.id} activeClassName="mobile-active" className={setClassActive(link)} to={"/" + link.frontmatter.url}>
                   {link.frontmatter.url_title}
                   { (link.frontmatter.url === data.pageContext.parentUrl) && data.pageContext.nestedPages &&
                   <ul><NavMobileSubmenu sublinks={data.pageContext.nestedPages}></NavMobileSubmenu></ul>
                   }
                  </NavItem>
            
                ))
              }


    </>
  )
}

export default NavMobileLinks